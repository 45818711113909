<!-- 用户协议 -->
<template>
  <div class="deal">

    <h1>用户协议</h1>  
    <p>常州汉诺数字科技有限公司(以下简称“常州汉诺”)在此特别提醒您在注册成为用户之前，请认真阅读本《用户协议》(以下简称“协议”)，确保您充分理解本协议中各条款。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。</p>
    <p>本协议约定常州汉诺与用户之间关于“NUOLINK”软件服务(以下简称“本服务”)的权利义务。“用户”是指注册、登录、使用本服务的个人或组织。用户对本协议的接受即自愿接受全部条款的约束，包括接受常州汉诺对任一服务条款随时所做的任何修改。本协议可由常州汉诺随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本软件查阅最新版协议条款。在常州汉诺修改协议条款后，如果用户不接受修改后的条款，请立即停止使用常州汉诺提供的服务，用户继续使用常州汉诺提供的服务将被视为接受修改后的协议</p>

    <h3>一、账号注册</h3>
    <ul>
      <li>1.用户在使用本服务前需要授权注册一个“NUOLINK”账号。“NUOLINK”账号应当使用手机号码授权注册，请用户使用尚未与“NUOLINK”账号绑定的手机号码，以及未被常州汉诺根据本协议封禁手机号码注册“NUOLINK”账号。常州汉诺可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而且无须事先通知用户。</li>
      <li>2.“NUOLINK”系基于电动车充电及周边综合服务的移动管理产品。您一旦接受本协议，则视为同意常州汉诺在软件使用过程中自动提取您授权的手机号码及用户信息用于软件服务的任何阶段。</li>
      <li>3.在用户注册及使用本服务时，常州汉诺需要搜集能识别用户身份的个人信息以便常州汉诺可以在必要时联系用户，或为用户提供更好的使用体验。常州汉诺对这些信息的使用将受第三条用户个人隐私信息保护的约束。</li>
    </ul>

    <h3>二、服务内容</h3>
    <ul>
      <li>1.本服务的具体内容由常州汉诺根据实际情况提供，包括但不限于授权用户通过其账号查看车辆充电、车况、车身定位、售后服务等。常州汉诺可以对其提供的服务予以变更，且常州汉诺提供的服务内容可能随时变更;用户将会收到常州汉诺关于服务变更的通知。</li>
      <li>2.常州汉诺提供的服务包含免费服务与收费服务用户可以通过付费方式购买收费服务，对于收费服务，常州汉诺会在用户使用之前给予用户明确的提示，只有用户根据提示确认并同意按照前述支付方式支付费用并完成了支付行为，用户才能使用收费服务。支付行为的完成以付费渠道的确认通知为准。</li>
    </ul>

    <h3>三、用户个人隐私信息保护</h3>
    <ul>
      <li>1.个人隐私信息是指涉及用户个人身份或个人隐私的信息，比如:手机号码、手机设备识别码、IP地址等。非个人隐私信息是指用户对本服务的操作状态以及使用习惯等明确且客观反映在常州汉诺服务器端的基本记录信息、个人隐私信息范围外的其它普通信息，以及经用户同意公开的信息。</li>
      <li>2.尊重用户个人隐私信息的私有性是常州汉诺的一贯原则，常州汉诺将采取技术措施和其他必要措施，确保用户个人隐私信息安全，防止在本服务中使用的用户个人隐私信息泄露、毁损或丢失。在发生上述情形或发现存在发生上述情形的可能时，常州汉诺将及时采取补救措施。</li>
      <li>3.未经用户同意常州汉诺不会向任何第三方公开、透露用户个人隐私信息。但以下情形不属于常州汉诺透露用户信息的范畴内:
        (1)根据国家相关法律法规规定，常州汉诺须配合国家有关部门提供用户的个人隐私信息;
        (2)用户自行向第三方公开其个人隐私信息;
        (3)用户与常州汉诺及合作单位之间就用户个人隐私信息的共同使用达成约定，常州汉诺因此向合作单位公开用户个人隐私信息;
        (4)任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露。
      </li>
      <li>4.用户同意常州汉诺可在以下事项中使用用户的个人隐私信息:
        (1)常州汉诺向用户发送重要通知，如软件更新、本协议条款的变更等;
        (2)常州汉诺内部进行审计、数据分析和研究等以改进常州汉诺的产品、服务和与用户之间的沟通;
        (3)依本协议约定，常州汉诺管理、审查用户信息及进行相应处理;
        (4)用法律法规规定的其他事项。
        除上述事项外，如未取得用户同意，常州汉诺不会将用户个人隐私信息使用于任何其他用途
      </li>
      <li>5.为了完善常州汉诺的技术和服务，向用户提供更好的服务体验常州汉诺有权自行收集使用用户的个人隐私信息及非个人隐私信息或向第三方提供用户的非个人隐私信息。</li>
    </ul>

    <h3>四、帐户管理</h3>
    <ul>
      <li>1.“NUOLINK”账号的所有权归常州汉诺所有，用户完成申请注册手续后，获得“NUOLINK”账号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。常州汉诺因经营需要，有权回收用户的“NUOLINK”账号。</li>
      <li>2.用户应遵守本协议的各项条款，正确、适当地使用本服务，如用户违反本协议中的任何条款，常州汉诺在通知用户后有权暂停或终止对违约用户的“NUOLINK”账号提供服务。同时，常州汉诺保留在任何时候收回“NUOLINK”账号、用户名的权利。</li>
      <li>3.用户不得利用“NUOLINK”账号或本服务进行如下行为:
        (1)提交、发布虚假信息;
        (2)盗用他人头像或资料，冒充、利用他人名义;
        (3)利用技术手段篡改他人账号;
        (4)利用NUOLINK”账号或本服务从事任何违法犯罪活动;
        (5)制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否有商业目的;
        (6)其他违反法律法规规定、侵犯其他用户合法权益干扰“NUOLINK”正常运营或常州汉诺未明示授权的行为。
      </li>
    </ul>

    <h3>五、数据储存</h3>
    <ul>
      <li>1.常州汉诺不对用户在本服务中相关数据的删除或储存失败负责</li>
      <li>2.常州汉诺可以根据实际情况自行决定用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。</li>
      <li>3.如用户停止使用本服务或本服务终止，常州汉诺可以从服务器上永久地删除用户的数据。本服务停止、终止后，常州汉诺没有义务向用户返还任何数据。</li>
    </ul>

    <h3>六、知识产权声明</h3>
    <ul>
      <li>1.除本服务中所涉及广告的知识产权由相应权利人享有外，常州汉诺在本服务中提供的内容(包括但不限于网页、文字、图片、音频、视频、图表等)的知识产权均归常州汉诺所有，但用户在使用本服务前对自己发布的内容已合法取得知识产权的除外。</li>
      <li>2.除另有特别声明外，常州汉诺提供本服务时所依托软件的著作权、专利权及其他知识产权均归常州汉诺所有。</li>
      <li>3.常州汉诺在本服务中所涉及的“NUOLINK”图形、文字或其组成，以及其他常州汉诺标志及产品、服务名称(以下统称“常州汉诺标识”)，其著作权或商标权归常州汉诺所有。未经常州汉诺书面同意，用户不得将常州汉诺标识以任何方式展示或使用或用作其他用途，也不得向他人表明用户有权展示、使用、或处理常州汉诺标识的行为。</li>
      <li>4.上述及其他任何常州汉诺或相关权利人依法拥有的知识产权均受到法律保护，未经常州汉诺或相关权利人书面许可，用户不得以任何形式进行使用或创造相关衍生作品。常州汉诺将仅以接收到的用户提交的信息，在第三方角度以合理的判断进行操作。</li>
    </ul>

    <h3>七、风险承担</h3>
    <ul>
      <li>1.用户理解并同意“NUOLINK”仅为用户提供电动车车况获取以及售后服务的平台，用户必须为自己注册账号下的一切行为负责，包括用户所发布的任何内容以及由此产生的任何后果。用户应对“NUOLINK”及本服务中提供的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。常州汉诺不会对因用户行为而导致的任何损失或损害承担责任。如果用户发现任何人违反本协议约定或以其他不当的方式使用本服务，请立即向常州汉诺举报或投诉，常州汉诺将依本协议约定进行处理。</li>
      <li>2.用户理解并同意，因业务发展需要，常州汉诺保留单方面对本服务的全部或部分服务内容变更、暂停、终止或撤销的权利，用户需承担此风险。</li>
    </ul>

    <h3>八、法律责任</h3>
    <ul>
      <li>1.如果常州汉诺发现或收到他人举报或投诉用户违反本协议约定的，常州汉诺有权不经通知随时对相关内容，包括但不限于用户资料、论坛文章进行审查、删除，并视情节轻重对违规账号进行处理，处理包括但不限于警告、账号封禁。处理后常州汉诺会通知用户处理结果。</li>
      <li>2.用户理解并同意，常州汉诺有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。</li>
      <li>3.用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的索赔、要求或损失，包括合理的律师费、诉讼费、保全费等由违规用户承担。常州汉诺将仅以接收到的用户提交的信息，在第三方角度以合理的判断进行操作。</li>
    </ul>

    <h3>九、不可抗力及其他免责事由</h3>
    <ul>
      <li>1.用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害(如洪水、地震、瘟疫流行和风暴等)以及社会事件(如战争、动乱、政府行为等)。出现上述情况时，常州汉诺将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，常州汉诺及合作单位在法律允许的范围内免责。</li>
      <li>2.本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰;用户下载安装的其它软件或访问的其他手机应用软件中含有“特洛伊木马”等病毒，威胁到用户的手机信息和数据的安全，继而影响本服务的正常使用等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。</li>
      <li>3.用户理解并确认，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，常州汉诺不承担任。常州汉诺将仅以接收到的用户提交的信息，在第三方角度以合理的判断进行操作。</li>
      <li>4.用户理解并确认，在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的署名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，常州汉诺不承担任何责任。常州汉诺将仅以接收到的用户提交的信息，在第三方角度以合理的判断进行操作。</li>
      <li>5.用户理解并确认，常州汉诺需要定期或不定期地对“NUOLINK”平台或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，常州汉诺无需为此承担任何责任。</li>
      <li>6.常州汉诺依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成常州汉诺的义务或承诺，常州汉诺不能保证及时发现违法违规或违约行为或进行相应处理。</li>
      <li>7.用户理解并确认，对于常州汉诺向用户提供的下列产品或者服务的质量缺陷及其引发的任何损失，常州汉诺无需承担任何责任:
        (1)常州汉诺向用户免费提供的服务;
        (2)常州汉诺向用户赠送的任何产品或者服务。
      </li>
      <li>8.在任何情况下，常州汉诺均不对任何间接性后果性、惩罚性、偶然性、特殊性或刑罚性的损害(包括因用户使用“NUOLINK”或本服务而遭受的利润损失)承担责任，即使常州汉诺已被告知该等损失的可能性亦然。尽管本协议中可能含有相悖的规定，常州汉诺对用户承担的全部责任，无论因何原因或何种行为方式，始终不超过用户因使用常州汉诺提供的服务而支付给常州汉诺的费用(如有)，最高赔偿额为用户在损害事实发生之日前三个月因使用常州汉诺提供的服务合计支付给常州汉诺的费用。</li>
    </ul>

    <h3>十、服务的变更、中断、终止</h3>
    <ul>
      <li>1.鉴于网络服务的特殊性，用户同意常州汉诺有权随时变更、中断或终止部分或全部的服务(包括收费服务)。常州汉诺变更、中断或终止的服务，常州汉诺应当在变更、中断或终止之前通知用户，并应向受影响的用户提供等值的替代性的服务。</li>
      <li>2.如发生下列任何一种情形，常州汉诺有权变更、中断或终止向用户提供的免费服务或收费服务而无需对用户或任何第三方承担任何责任:
        (1)根据法律规定用户应提交真实信息，而用户提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明;
        (2)用户违反相关法律法规或本协议的约定:
        (3)按照法律规定或有权机关的要求
        (4)出于安全的原因或其他必要的情形。
      </li>
    </ul>

    <h3>十一、其他</h3>
    <ul>
      <li>1.常州汉诺郑重提醒用户注意本协议中免除常州汉诺责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。</li>
      <li>2.本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和常州汉诺之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交至在常州汉诺注册地址有管辖权的人民法院管辖。</li>
      <li>3.本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方均具有约束力。</li>
    </ul>
</div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color:#fff')
  },
  methods:{

  }
};
</script>

<style lang="less">
.deal {
  padding: 10px 12px 30px;
  h1 {
    font-size: 24px;
    text-align: center;
    margin: 10px 0;
  }
  h3 {
    font-size: 20px;
    margin: 20px 0 10px;
  }
  p,li {
    font-size: 16px;
    line-height:26px;
  }
  li {
    margin-bottom: 10px;
  }
}
</style>